export const DEFAULT_SORT = {
  sort: "desc",
  colId: "createdOn",
};

export const IS_CUSTOMIZED = {
  yes: "Yes",
  no: "No",
  default: "-",
};

export const MORE_MENU_ACTION_NAME = {
  SOFT_CLOSE: "Soft Close",
  REACTIVATE: "Reactivate",
  VIEWLOG: "View log",
  CLOSEPROJECT: "Close project",
  WORKFLOWS: "Workflows",
  ACCESSRESTRICTIONS: "Access Restrictions",
  ACCESSRESTRICTIONALL: "All",
  ACCESSRESTRICTIONNONE: "None",
  ACCESSRESTRICTIONPROJECTADMIN: "ProjectAdministrator",
  RETRY_DBS_TRANSMISSION: "Retry",
};

export const DBS_TRANSMISSION_STATUS = {
  Success: "Success",
  Failed: "Failed",
  InProgress: "In Progress"
};
