import * as type from "../action-types";
import { FeedbackType } from "../../components/MotifCloseProjectPopup/CloseProjectPopup";

type load = { id: string };

export const getAdminProjectsAction = (payload: load) => ({
  type: type.GET_ADMIN_PROJECTS,
  payload,
});

export const updateAdminProjectAction = (payload: load) => ({
  type: type.UPDATE_ADMIN_PROJECT,
  payload,
});

export const flushUpdatedAdminProjectAction = (payload: load) => ({
  type: type.FLUSH_UPDATED_PROJECT_DATA,
  payload,
});

export const searchAdminProjectAction = (payload: load) => ({
  type: type.SEARCH_ADMIN_PROJECT,
  payload,
});

export const softDeleteAdminProjectsAction = (payload?: load) => ({
  type: type.SOFT_DELETE_PROJECT,
  payload,
});

export const reactivateAdminProjectsAction = (payload?: load) => ({
  type: type.REACTIVATE_PROJECT,
  payload,
});

export const deleteAdminProjectsAction = (
  payload?: load & { feedback: FeedbackType }
) => ({
  type: type.DELETE_ADMIN_PROJECT,
  payload,
});

export const flushAdminProjectAction = (payload?: load) => ({
  type: type.FLUSH_ADMIN_PROJECT,
});

export const getAdminAppsRequest = (payload: load) => ({
  type: type.GET_ADMIN_APPS_REQUEST,
  payload,
});

export const getAdminAppsSuccess = (payload: load) => ({
  type: type.GET_ADMIN_APPS_SUCCESS,
  payload,
});

export const getAdminAppsFailure = (payload: load) => ({
  type: type.GET_ADMIN_APPS_FAILURE,
  payload,
});

export const getAdminAppsVideo = (payload: load) => ({
  type: type.GET_ADMIN_APPS_VIDEO_REQUEST,
  payload,
});

export const deleteAdminAppsVideo = (payload: load) => ({
  type: type.DELETE_ADMIN_APPS_VIDEO_REQUEST,
  payload,
});

export const getAdminAppsThumbnail = (payload: load) => ({
  type: type.GET_ADMIN_APPS_THUMBNAIL_REQUEST,
  payload,
});

export const deleteAdminAppsThumbnail = (payload: load) => ({
  type: type.DELETE_ADMIN_APPS_THUMBNAIL_REQUEST,
  payload,
});

export const deleteAdminAppAction = (payload?: load) => ({
  type: type.DELETE_ADMIN_APP_REQUEST,
  payload,
});

export const flushAdminAppAction = (payload?: load) => ({
  type: type.FLUSH_ADMIN_APP,
});

export const getProjectLogsAction = (payload: load) => ({
  type: type.GET_PROJECT_LOGS_REQUEST,
  payload,
});

export const searchProjectLogsAction = (payload: load) => ({
  type: type.SEARCH_PROJECT_LOGS,
  payload,
});

export const getAdminProjectsByUserRequest = (payload?: load) => ({
  type: type.GET_ADMIN_PROJECTS_BY_USER_REQUEST,
  payload,
});

export const getAdminProjectsByUserSuccess = (payload: load) => ({
  type: type.GET_ADMIN_PROJECTS_BY_USER_SUCCESS,
  payload,
});

export const getAdminProjectsByUserFailure = (payload: load) => ({
  type: type.GET_ADMIN_PROJECTS_BY_USER_FAILURE,
  payload,
});

export const flushAdminProjectByUserAction = (payload?: load) => ({
  type: type.FLUSH_ADMIN_PROJECTS_BY_USER,
});

export const searchAdminAppsAction = (payload: load) => ({
  type: type.SEARCH_ADMIN_APPS_REQUEST,
  payload,
});

export const flushProjectLogs = () => ({
  type: type.FLUSH_PROJECT_LOGS,
});

export const updateCustomizationInfoRequest = (payload: load) => ({
  type: type.UPDATE_CUSTOMIZATION_INFO_REQUEST,
  payload,
});

export const updateCustomizationInfoSuccess = (payload: load) => ({
  type: type.UPDATE_CUSTOMIZATION_INFO_SUCCESS,
  payload,
});

export const updateCustomizationInfoFailure = (payload: load) => ({
  type: type.UPDATE_CUSTOMIZATION_INFO_FAILURE,
  payload,
});

export const exportProjectList = (payload: load) => ({
  type: type.EXPORT_PROJECT_LIST,
  payload,
});

export const exportProjectListSuccess = (payload: load) => ({
  type: type.EXPORT_PROJECT_LIST_SUCCESS,
  payload,
});

export const exportProjectListFailure = (payload: load) => ({
  type: type.EXPORT_PROJECT_LIST_FAILURE,
  payload,
});

export const flushExportProjectListStatus = () => ({
  type: type.FLUSH_EXPORT_PROJECT_LIST_STATUS,
});

export const setProjectAccessRestriction = (payload) => {
  return {
    type: type.SET_PROJECT_ACCESS_RESTRICTIONS,
    payload,
  };
};

export const retryDbsTransmission = (payload) => {
  return {
    type: type.SET_RETRY_DBS_TRANSMISSION,
    payload,
  };
};
