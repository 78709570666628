import React, { FC ,useEffect,useRef,useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import AppInput from '../appInput/appInput';
import TablePagination from '@material-ui/core/TablePagination';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AppIcon from '../appIcon/appIcon';
import { doubleLeft, doubleRight,angleLeft, angleRight} from '../../theme/icons';
import { DEFAULT_PAGE_SIZES } from './appPaginationConstants'
import "./appPagination.scss";

interface Iprops {
  maxCount?: number;
  onChangePage?: Function;
  pageNumber?: number;
  pageSize?: number;
  currentPage?: number;
  maxPage?: number;
  onChangeRowsPerPage?: Function;
}

const AppPagination: FC<Iprops> = ({ maxCount = 0, pageSize = 50, currentPage = 0, maxPage = 0, pageNumber: initialPageNumber = 1, onChangePage, onChangeRowsPerPage }) => {
  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChangePage = (newPage) => {
    onChangePage && onChangePage(newPage)
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current?.querySelector('input')?.focus();
    }
  }, [pageNumber]);

  const handleChangeRowsPerPage = ({ target }) => {
    onChangeRowsPerPage && onChangeRowsPerPage(parseInt(target.value))
    setRowsPerPage(parseInt(target.value));
  };
  
  const handleFirstPageButtonClick = () => {
    handleChangePage(1);
    setPageNumber(1);
  };

  const handleBackButtonClick = () => {
    handleChangePage(pageNumber - 1);
    setPageNumber(pageNumber - 1);
  };

  const handleNextButtonClick = () => {
    handleChangePage(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const handleLastPageButtonClick = () => {
    handleChangePage(maxPage);
    setPageNumber(maxPage);
  };
  const handlePageNumberChange = (event) => {
    if (event && event.target) {
      const value = parseInt(event.target.value?.trim(), 10);
      if (!isNaN(value)) {
        if(value < 100000 && value <= maxPage) setPageNumber(value);
      } else if(event.target.value?.trim() === '') {
        setPageNumber(null);
      }
    }
  };

  const handlePageOnBlur = (event) => {
    if (event?.target) {
      const value = parseInt(event.target.value?.trim(), 10);
      if (!isNaN(value))
        handleChangePage(value);
    }
  };
  
  const handlePageSearch = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter')  {
      handleChangePage(pageNumber);
    }
  };
  const renderArrows = () => {
    return (
      <div className="app-pagination-buttons">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={pageNumber === 1 || pageNumber === null}
        >
          <AppIcon icon={doubleLeft} />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={pageNumber === 1 || pageNumber === null}
        >
         <AppIcon icon={angleLeft} />
        </IconButton>
        <AppInput inputRef={inputRef} type="text" value={pageNumber > 0 ? pageNumber.toString() : ''} onBlur={handlePageOnBlur} onChange={handlePageNumberChange} onKeyPress={handlePageSearch}/>
        <span className="page-count"> 
          {`/ ${maxPage}`}
        </span>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={pageNumber >= maxPage || pageNumber === null}
        >
          <AppIcon icon={angleRight} />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={pageNumber >= maxPage || pageNumber === null}
        >
          
          <AppIcon icon={doubleRight} />
        </IconButton>
      </div>)
  }
  return (
    <div className="app-pagination">
      <TablePagination
        component="div"
        count={maxCount}
        page={Math.max(pageNumber - 1, 0)}
        rowsPerPageOptions={DEFAULT_PAGE_SIZES}
        rowsPerPage={rowsPerPage || pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        ActionsComponent={renderArrows}
        labelRowsPerPage=""
        SelectProps={{IconComponent:ExpandMore}}
      />
    </div>
  );
}

export default AppPagination;
