import React, { useState, useEffect, useRef } from "react";
import copilotThumb from "../../assets/images/CE_assistant_small.png";
import { Drawer, Backdrop } from "@material-ui/core";
import { externalAppSettings } from "../../utils/Constants";
import { IAssistantDetails } from "../../components/createProject/models/project";
import en_translation from "../../localization/en/translation.json";
import { returnSvgImage } from "./assistantContainerIcons";
import "./assistantContainer.scss";

interface IAssistantContainerProps extends IAssistantDetails {
  isProjectNonActive: boolean;
}

export const AssistantContainer = (
  assistantDetails: IAssistantContainerProps
) => {
  const [flyout, setFlyout] = useState<boolean>(false);
  const [loadIframe, setLoadIframe] = useState<boolean>(false);
  const [width, setWidth] = useState(false);
  const [disableAssistant, setDisableAssistant] = useState<boolean>(true);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const sspIframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (assistantDetails.isActive) {
      setDisableAssistant(
        !assistantDetails.isActive || assistantDetails.isProjectNonActive
      );
    }
  }, [assistantDetails]);

  useEffect(() => {
    setWidth(false);
  }, []);

  const toggleWidth = () => {
    setWidth(!width); // Toggle between 50vw and 100vw
  };

  const openFlyout = () => {
    setFlyout(true);
    setLoadIframe(true);
  };

  const closeFlyout = () => {
    setFlyout(false);
  };

  const CustomBackdrop = ({ ...props }) => (
    <Backdrop {...props} onClick={(e) => e.stopPropagation()} />
  );

  const handleIframeLoad = () => {
    const timerId = setTimeout(() => {
      const iframe = sspIframeRef?.current;
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.postMessage(
            externalAppSettings,
            assistantDetails.siteURL
          );
        }
      }
    }, 1000);

    return () => clearTimeout(timerId);
  };

  return (
    <>
      <div className="assistant-flyout">
        <button
          className={`assistant-button ${disableAssistant ? "disabled" : ""}`}
          onClick={openFlyout}
          disabled={disableAssistant}
        >
          <img
            src={copilotThumb}
            alt="CE Assistant"
            className="assistant-flyout-image"
          />
          <span className="flyout-button-text">
            {" "}
            {en_translation.assistant_chat_title}{" "}
          </span>
        </button>
      </div>

      <Drawer
        className={`drawer`}
        anchor="right"
        open={flyout}
        onClose={closeFlyout} // Use closeFlyout handler to close the flyout
        classes={{
          paper: width ? "drawerPaper-fullWidth" : "drawerPaper",
        }}
        BackdropComponent={CustomBackdrop} // custom backdrop to disable outside click
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div>
          <div className="assistant">
            <div className="header-title">
              <p className="title">
                {en_translation.assistant_chat_title}
                <span className="beta-logo">
                  {en_translation.assistant_beta_label}
                </span>
                <span
                  className="help-tooltip-icon"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  {returnSvgImage("helpIcon")}
                  {showTooltip && (
                    <div className="custom-tooltip">
                      {en_translation.assistant_beta_help_tooltip_text}
                    </div>
                  )}
                </span>
              </p>
              <p className="subtitle">
                {" "}
                {en_translation.assistant_flyout_sub_title}
              </p>
            </div>

            <div className="ml-auto">
              {width ? (
                <span onClick={toggleWidth}>{returnSvgImage("collapse")}</span>
              ) : (
                <span onClick={toggleWidth}>{returnSvgImage("expand")}</span>
              )}
              <span onClick={closeFlyout}>{returnSvgImage("close")}</span>
            </div>
          </div>
          {loadIframe && (
            <iframe
              ref={sspIframeRef}
              title="Assistant"
              id="assistantIframe"
              src={assistantDetails.siteURL}
              style={{
                border: "none",
                height: "calc(100vh - 10px - 44px - 45px)",
                width: "100%",
              }}
              onLoad={handleIframeLoad}
            ></iframe>
          )}
        </div>
      </Drawer>
    </>
  );
};
