declare global{
    interface Window {
       pendo:any;
    }
  }
  export {};

  export const initializePendo = (userData:any) => {
    if(window.pendo){
       window.pendo.initialize({
           apiKey: '84e4cdad-d0e0-41fe-5a2d-705a8f353185',
           visitor: {id:userData.mail,   },
           account: {id:userData.mail,  }
       });
    }
  }