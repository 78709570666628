import { ReactNode } from "react";
import { AppClass } from "../../components/appCard/models/appClass";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";

export interface ProjectModel {
  accessRestrictionKey: string;
  appDetails: AppClass[];
  appPlatformDetails: AppPlatformClass[];
  appPlatforms: string;
  clientName: string;
  createdBy: string;
  createdOn: string;
  id: string;
  name: string;
  projectCategoryId: string;
  projectCategoryName?: string;
  projectTypeName: string;
  curentContextUserProjectAccess: boolean;
  bundleName: string;
  channelName: string;
  regionName: string;
  locationName: string;
  noOfProjectUsers: string;
  status: string;
  deleted: boolean | null;
  softCloseDate: number | null;
  softClosedBy: string | null;
  projectFriendlyId?: string;
  paceId?: string;
  additionalPaceIds?: string | null;
  description?: string;
  ppeddApprover: string;
  isPricingIncluded?: boolean;
  serviceLine?: string;
  dbsTransmissionStatus?: string
}

export interface ProjectRowDataModel {
  projectName: ReactNode;
  id: string;
  clientName: string;
  projectFriendlyID: string;
  projectCategoryId: string;
  additionalPaceIds: ReactNode;
  raw_additionalPaceIds: string | undefined | null;
  description: ReactNode;
  apps: ReactNode;
  type: string;
  bundle: string;
  paceId: string;
  region: string;
  dataHostingLoc: string;
  noOfUsers: ReactNode;
  createdBy: ReactNode;
  createdOn: string;
  status: ReactNode;
  action: ReactNode;
}

export enum EProjectStatuses {
  DRFT = "draft",
  ACTV = "active",
  LCKD = "locked",
  CLSD = "closed",
  EROR = "error",
  PND = "pending",
  RDY = "ready",
}

export interface ProjectDetailsRowDataModel {
  projectName: ReactNode;
  type: string;
  dataHostingLoc: string;
  noOfUsers: ReactNode;
  status: ReactNode;
}

export interface ProjectLogsRowDataModel {
  timestamp: string;
  severity?: string;
  email?: string;
  message: string;
}
